/* eslint-disable react/jsx-no-target-blank */
import React from "react"
import SEO from "../components/seo"
import useScript from "../hooks/use-script"
import { Link, withPrefix } from "gatsby"
import Layout from "../components/layout"
const DocumentSigning = (): JSX.Element => {
  useScript(withPrefix("js/show-on-scroll.js"))

  useScript(withPrefix("js/sticky-header.js"))

  useScript(withPrefix("animations/reusabletemplates/reusabletemplates.js"))

  useScript(
    withPrefix("animations/reusabletemplates/reusabletemplateslotte.js")
  )
  const schema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebPage",
        url: "https://documentesign.com/reusable-templates/",
        name:
          "Create, Manage, Share Reusable Document Templates online | Document eSign",
        isPartOf: {
          "@type": "WebSite",
          "@id": "https://documentesign.com/reusable-templates/#website",
        },
        datePublished: "2021-02-16T07:56:57+00:00",
        dateModified: "2021-04-28T16:44:56+00:00",
        description:
          "Reuse and reshare your saved templates with document eSign digital reusable templates.",
        inLanguage: "en-US",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: ["https://documentesign.com/reusable-templates/"],
          },
        ],
      },
    ],
  }
  return (
    <>
      <Layout>
        <SEO
          title="Create, Manage, Share Reusable Document Templates online | Document eSign"
          description="Reuse and reshare your saved templates with document eSign digital reusable templates."
          schemaMarkup={schema}
        />
        <div className="feature-details reusable-templates">
          <div className="intro show-on-scroll ani-in">
            <div className="container position-relative mb-md-n5">
              <div className="row">
                <div className="col-lg-10 offset-lg-1 text-center pt-lg-5">
                  <div className="h4 pt-5 pb-3">REUSABLE TEMPLATES</div>
                  <h1 className="mb-lg-n4">
                    Create reusable document <br /> templates online
                  </h1>
                </div>
                <div className="col-md-6 offset-md-3 mt-md-n5 text-center">
                  <div
                    id="reusabletemplates_lottie_container"
                    className="feature-lottie-ani"
                  ></div>
                </div>
              </div>
            </div>
            <div className="bg-shape d-none d-lg-block">
              <svg
                width="2560px"
                height="100px"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                preserveAspectRatio="none"
                x="0px"
                y="0px"
                viewBox="0 0 2560 100"
                xmlSpace="preserve"
                enableBackground="new 0 0 2560 100"
              >
                <polygon points="2560 0 2560 100 0 100"></polygon>
              </svg>
            </div>
          </div>
          <div className="container mt-md-5 pt-md-5">
            <div className="row pt-md-4">
              <div className="col-md-6 offset-md-3 text-center show-on-scroll ani-in">
                <p className="text-light-100 pt-4 pt-md-0">
                  Document eSign reusable templates allows you reuse and resend
                  your saved templates and help you quickly collect signatures
                  without starting from scratch every time. Electronic Reusable
                  Templates help in the signature request workflows for
                  standardized documents that saves administrative time and
                  simplifies the process of preparing and sharing documents for
                  electronic signature. Our digital signature reusable templates
                  saves you the trouble of adding fields each time you have to
                  send the document out for signature.
                </p>
                <a
                  href="https://app.documentesign.com/auth/signup"
                  target="_blank"
                  className="btn btn-dark px-md-5 mt-2 mb-2"
                >
                  Get started
                </a>
                <br />
                <small className="text-muted">
                  <strong>Free forever,</strong> No credit card required
                </small>
              </div>
            </div>
          </div>
          <div className="container py-5">
            <div className="card features-bx mb-5">
              <img
                src={withPrefix("images/rt-top-particles.svg")}
                className="img-fluid show-on-scroll ani-in"
                alt="..."
              />

              <div className="card-body px-lg-5 mt-md-n5 py-0">
                <div className="row">
                  <div className="col-sm-6 mb-4 mb-sm-5 show-on-scroll ani-in">
                    <div className="feature-icon mb-3">
                      <i className="fas fa-file-contract"></i>
                    </div>

                    <div className="h4">Reusable smart templates</div>
                    <p className="text-light-100">
                      Our digital signature reusable templates lets customers
                      use documents that can be signed by a different set of
                      recipients each time.
                    </p>
                  </div>
                  <div className="col-sm-6 mb-4 mb-sm-5 show-on-scroll ani-in">
                    <div className="feature-icon mb-3">
                      <i className="fas fa-certificate"></i>
                    </div>

                    <div className="h4">Standardized processes</div>
                    <p className="text-light-100">
                      Electronic templates help you standardize your workflow
                      across teams by eliminating manual steps and thereby
                      reducing errors.
                    </p>
                  </div>
                  <div className="col-sm-6 mb-4 mb-sm-5 show-on-scroll ani-in">
                    <div className="feature-icon mb-3">
                      <i className="far fa-window-restore"></i>
                    </div>

                    <div className="h4">Faster &amp; hassle-free workflow</div>
                    <p className="text-light-100">
                      Our digital Reusable templates service helps you create
                      templates by dragging and dropping editable signature
                      fields into place that can be used for common documents.
                    </p>
                  </div>
                  <div className="col-sm-6 mb-4 mb-sm-5 show-on-scroll ani-in">
                    <div className="feature-icon mb-3">
                      <i className="fas fa-file-signature"></i>
                    </div>

                    <div className="h4">Customizable templates</div>
                    <p className="text-light-100">
                      Use Document Esign reusable templates services to import
                      templates into the document editor to create and configure
                      templates that can be accessed online.
                    </p>
                  </div>
                </div>
              </div>
              <div className="feature-bx-cta text-center show-on-scroll ani-in">
                <Link to="/features/" className="btn btn-outline-dark">
                  Explore more features
                  <i className="fas fa-arrow-right pl-2"></i>
                </Link>
              </div>
              <img
                src={withPrefix("images/rt-bottom-particles.svg")}
                className="img-fluid show-on-scroll ani-in"
                alt="..."
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default DocumentSigning
